<template>
  <div class="reason-card grid-sm-4 grid-md-4 grid-lg-4">
    <div class="reason-card__wrapper">
      <div class="reason-card__image-holder">
        <img class="reason-card__image"
             :src="image"
             alt/>
      </div>
      <div class="reason-card__meta">
        <h5 class="reason-card__title">{{title}}</h5>
        <p class="reason-card__description">{{description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'ReasonCard',
  props: {
    params: {},
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    title() {
      return this?.params?.title;
    },
    description() {
      return this?.params?.description;
    },
    image() {
      if (this.params && this.params.images && this.params.images.length > 0) {
        return this.params.images[0];
      }
      return errorImage;
    },
  },
};
</script>
